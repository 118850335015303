import React from 'react';
import Box from '@mui/material/Box';
import ContextConfiguration from "./ContextConfiguration";

const ContentManagementPage = () => {
  return (
      <Box sx={{ width: '100%' }}>
              <Box>
                  <ContextConfiguration />
              </Box>
      </Box>
  );
}

export default ContentManagementPage
