import React, {useEffect, useMemo, useState} from "react";
import {Race, Event, RaceContextParsed, RaceWebContextDto} from "../../../types/models";
import NeurunApi from "../../../api/neurun";
import {Box, Tabs, Tab, TextField, CircularProgress, Typography} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RaceOrganizerInfo from "../EditorInformation";

interface SelectBrandsComponentProps {
  race?: Race | null;
  event?: Event | null;
  onChange: (value: RaceContextParsed) => void;
}

const ContextEditorComponent: React.FC<SelectBrandsComponentProps> = (props) => {
  const { race, event, onChange } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [parsedContext, setParsedContext] = useState<RaceContextParsed>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getContext();
  }, []);

  useEffect(() => {
    onChange(parsedContext)
  }, [parsedContext]);
  const activeTabKey = useMemo(() => Object.keys(parsedContext || {})[activeTab], [parsedContext, activeTab]);

  const getContext = async () => {
    setIsLoading(true);
    if (!race?.guid) return
    const result = await NeurunApi.getParsedRaceContext(race?.guid);

    setParsedContext(result);
    setIsLoading(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedTexts = { ...parsedContext };
    const tabKey = Object.keys(parsedContext)[activeTab];
    if (tabKey !== 'web') {
      // @ts-ignore
      updatedTexts[tabKey] = event.target.value;
    }
    setParsedContext(updatedTexts);
  };

  const handleWebTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, record: RaceWebContextDto) => {
    const updatedTexts = { ...parsedContext };
    updatedTexts.web = updatedTexts.web?.map((data) => {
      if (data.link === record.link) {
        return {
          link: record.link,
          text: event.target.value,
        }
      }
      return {
        ...data,
      }
    })

    setParsedContext(updatedTexts);
  };

  const renderEditor = (activeTab: string) => {
    if (activeTab === 'web') {
      return (
          <Box
              sx={{
                maxHeight: '60vh',
                overflowY: 'auto',
                overflowX: 'hidden',
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '16px',
                backgroundColor: '#f9f9f9'
              }}
          >
            {parsedContext[activeTab]?.map((data, index) => (
                <div key={`website-context-${index}`}>
                  {renderAccordion(data)}
                </div>
            ))}
          </Box>
      )
    }
    return (
        <TextField
            multiline
            rows={20}
            fullWidth
            variant="outlined"
            style={{
              backgroundColor: '#f9f9f9'
            }}
            // @ts-ignore
            value={parsedContext[activeTab]}
            onChange={handleTextChange}
        />
    )
  }

  const renderAccordion = (data: RaceWebContextDto) => {
    return (
      <Accordion style={{ marginBottom: 5 }}>
        <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
        >
          <Typography component="span">{data.link}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
              multiline
              rows={20}
              fullWidth
              variant="outlined"
              value={data.text}
              onChange={(e) => handleWebTextChange(e, data)}
          />
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <Box p={2}>
      {isLoading || !parsedContext ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
          <CircularProgress color="info" />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <RaceOrganizerInfo raceUrl={`${window.location.origin}/race/${race?.external_id}?event_id=${event?.guid}`} />
          <Tabs value={activeTab} onChange={handleTabChange} aria-label="Text Editor Tabs">
            {Object.keys(parsedContext).map((tab) => <Tab key={tab} label={tab} />)}
          </Tabs>
          <Box sx={{ mt: 2 }}>
            {renderEditor(activeTabKey)}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ContextEditorComponent;

