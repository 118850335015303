import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Step, StepLabel, Stepper, Typography } from "@mui/material";
import SelectEventComponent from "../../components/WidgetCustomizationSteps/SelectEventComponent";
import CodeGeneratorComponent from "../../components/WidgetCustomizationSteps/CodeGeneratorComponent";
import CustomizeWidgetComponent from "../../components/WidgetCustomizationSteps/CustomizeWidgetComponent";
import { Event, OrderedRace } from "../../types/models";
import NeurunApi from "../../api/neurun";
import { useFormik } from "formik";

const steps = ['Select event', 'Customize widget', 'Generate embedded code'];

const WidgetCustomizationPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState<{ label: string; option: string } | null>(null);
  const [currentEvent, setCurrentEvent] = useState<Event | null>(null);
  const [events, setEvents] = useState<Event[] | null>(null);
  const [orderedRaces, setOrderedRaces] = useState<OrderedRace[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isNextDisabled = useMemo(() => {
    if (activeStep === 0 && !selectedEvent) return true;
    return false;
  }, [activeStep, selectedEvent])

  const widgetCustomizationFormik = useFormik({
    initialValues: {
      main_color: '#4B56BC'
    },
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: () => {}
  })

  useEffect(() => {
    if (selectedEvent && events?.length) {
      const event = events?.find(i => i.guid === selectedEvent.option);
      if (event) {
        widgetCustomizationFormik.resetForm()
        setCurrentEvent(event);
      }
    }
  }, [events, selectedEvent]);

  const isLastStep = useMemo(() => activeStep === steps.length - 1, [activeStep, steps?.length])

  const handleNext = async () => {
    if (activeStep === 1) {
      await updateEvent();
    }

    if (isLastStep) {
      resetState();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const updateEvent = async () => {
    try {
      if (currentEvent && orderedRaces) {
        setIsLoading(true);
        const raceOrderingConfig = orderedRaces.map(i => ({ guid: i.id, order: i.display_order }));
        const widgetConfiguration = {
          style: {
            main_color: widgetCustomizationFormik.values.main_color
          }
        }
        await NeurunApi.updateEvent(currentEvent?.guid, { race_ordering_config: raceOrderingConfig, widget_configuration: widgetConfiguration });
        setIsLoading(false);
      }
    } catch (err) {
      console.error('Failed to update race ordering:', err);
      setIsLoading(false);
      return;
    }
  }

  const resetState = () => {
    setActiveStep(0);
    setSelectedEvent(null);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEventChange = (event: React.ChangeEvent<{}>, value: { label: string; option: string } | null) => {
    setSelectedEvent(value);
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <SelectEventComponent value={selectedEvent} onChange={handleEventChange} setEvents={setEvents} />
      case 1:
        return <CustomizeWidgetComponent
          eventId={selectedEvent?.option || ''}
          event={currentEvent}
          races={orderedRaces}
          setRaces={setOrderedRaces}
          formik={widgetCustomizationFormik}
        />
      case 2:
        return <CodeGeneratorComponent eventId={selectedEvent?.option || ''} />;
    }
  };

  return (
    <Box sx={{width: '100%'}}>
      <h1>Widget Customization</h1>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{mt: 2, mb: 1}}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
            <Box sx={{flex: '1 1 auto'}}/>
          </Box>
        </React.Fragment>
      ) : (
        <Box minHeight='350px' display='flex' flexDirection='column' justifyContent='space-around'>
          <Box>
            {renderStepContent()}
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, marginTop: 'auto'}}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{mr: 1}}
            >
              Back
            </Button>
            <Box sx={{flex: '1 1 auto'}}/>
            <Button onClick={handleNext} disabled={isNextDisabled || isLoading}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default WidgetCustomizationPage
