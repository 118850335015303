import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Race } from "../../types/models";
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter";
import NeurunApi from "../../api/neurun";
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from "../../helpers/formatDate";
import { convertToTitleCase } from "../../helpers/convertToTitleCase";
import ArrowBackIcon from "../../assets/img/arrow-back-icon.svg";

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'distance',
    numeric: true,
    disablePadding: true,
    label: 'Distance',
  },
  {
    id: 'surface_type',
    numeric: false,
    disablePadding: true,
    label: 'Surface type',
  },
  {
    id: 'race_type',
    numeric: false,
    disablePadding: true,
    label: 'Race type',
  },
  {
    id: 'starting_at',
    numeric: false,
    disablePadding: true,
    label: 'Starting at',
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: true,
    label: 'Location',
  },
]

const RaceListPage = () => {
  const [races, setRaces] = useState<Race[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const secretKey = localStorage.getItem('secret_key');
    if (!secretKey) {
      navigate('/login');
      return;
    }

    if (params?.event_id) {
      getRaceList(params?.event_id)
    }
  }, []);

  const getRaceList = async (eventId: string) => {
    setIsLoading(true);
    const result = await NeurunApi.getEventRaceList(eventId);

    setRaces(result?.races || []);
    setIsLoading(false);
  };

  const onAddNewRaceClick = () => {
    navigate(`/race${params?.event_id ? `?event_id=${params?.event_id}` : ''}`)
  }

  const handleRowClick = (id: string) => {
    navigate(`/race/${id}${params?.event_id ? `?event_id=${params?.event_id}` : ''}`)
  }

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
          <div style={{width: '150px', display: 'flex', alignItems: 'center', cursor: 'pointer'}}
               onClick={() => navigate('/my-events')}>
            <img src={ArrowBackIcon} alt='Back icon' style={{width: '26px', height: '26px'}}/>
            <span>Back</span>
          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px'}}>
          <Typography variant='h4' fontWeight='500'>Race list</Typography>
          <button className='btn' style={{width: '150px', fontSize: '15px', padding: '10px'}}
                  onClick={onAddNewRaceClick}>Add new race
          </button>
        </div>
        {isLoading ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            mt={4}
          >
            <CircularProgress color='info'/>
          </Box>
        ) : (
          <div>
            {!races?.length && !isLoading ? (
              <Typography textAlign='center'>There are no races</Typography>
            ) : (
              <Paper sx={{width: '100%', mb: 2}}>
                <TableContainer>
                  <Table
                    sx={{minWidth: 750}}
                    aria-labelledby="tableTitle"
                    size='medium'
                  >
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align='left'
                            padding='normal'
                          >
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {races.map((row) => {
                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleRowClick(row?.external_id || '')}
                            tabIndex={-1}
                            key={row.guid}
                            sx={{cursor: 'pointer'}}
                          >
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">{row.distance}</TableCell>
                            <TableCell align="left"
                                       style={{minWidth: '110px'}}>{capitalizeFirstLetter(row.surface_type)}</TableCell>
                            <TableCell align="left">{convertToTitleCase(row.race_type || '')}</TableCell>
                            <TableCell align="left">{formatDate(row.starting_at)}</TableCell>
                            <TableCell align="left">{row.location}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
          </div>
        )}
      </Box>
    </div>
  )
}

export default RaceListPage;
