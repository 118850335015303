import React from "react";
import "./index.css";

interface CustomButtonProps {
  type?: "button" | "submit" | "reset";
  title?: string;
  disabled?: boolean;
  variant?: "primary" | "secondary" | "outlined";
  onClick?: () => void;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  iconPosition?: "prefix" | "suffix";
}

const CustomButton: React.FC<CustomButtonProps> = ({
   type = "button",
   title = "",
   disabled = false,
   variant = "primary",
   onClick,
   style,
   icon,
   iconPosition = "prefix",
 }) => {
  const getButtonClass = () => {
    switch (variant) {
      case "secondary":
        return "secondary-btn";
      case "outlined":
        return "outlined-btn";
      default:
        return "primary-btn";
    }
  };

  return (
    <button
      type={type}
      className={`btn ${getButtonClass()}`}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      {icon && iconPosition === "prefix" && <span className="btn-icon">{icon}</span>}
      {title && <span className="btn-title">{title}</span>}
      {icon && iconPosition === "suffix" && <span className="btn-icon">{icon}</span>}
    </button>
  );
};

export default CustomButton;
