import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import { useEffect, useMemo, useState } from "react";
import {Event, Race, RaceBrand} from "../../../types/models";
import SelectEventComponent from "../../../components/WidgetCustomizationSteps/SelectEventComponent";
import SelectRaceComponent from "../../../components/SelectRaceComponent";
import SelectBrandsComponent from "../../../components/BrandConfigurationSteps/SelectBrandsComponent";
import NeurunApi from "../../../api/neurun";
import { ToastContainer, toast } from 'react-toastify';

const steps = ['Select event', 'Select race', 'Select brands'];

const BrandConfiguration = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [selectedEvent, setSelectedEvent] = useState<{ label: string; option: string } | null>(null);
  const [selectedRace, setSelectedRace] = useState<{ label: string; option: string } | null>(null);
  const [currentRace, setCurrentRace] = useState<Race | null>(null);
  const [events, setEvents] = useState<Event[] | null>(null);
  const [races, setRaces] = useState<Race[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState<{ label: string; option: string }[]>([]); // Track selected options
  const [brands, setBrands] = useState<RaceBrand[] | null>(null);

  const isNextDisabled = useMemo(() => {
    if (activeStep === 0 && !selectedEvent) return true;
    return false;
  }, [activeStep, selectedEvent])

  useEffect(() => {
    if (selectedEvent && events?.length) {
      const event = events?.find(i => i.guid === selectedEvent.option);
      if (event) {
        setRaces(event?.races)
      }
    }
  }, [events, selectedEvent]);

  useEffect(() => {
    if (selectedRace && races?.length) {
      const race = races?.find(i => i.guid === selectedRace.option);

      if (race) {
        setCurrentRace(race)
      }
    }
  }, [races, selectedRace]);

  const totalSteps = () => steps.length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const handleNext = async () => {
    setCompleted((prevCompleted) => ({
      ...prevCompleted,
      [activeStep]: true,
    }));
    if (!isLastStep()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    if (isLastStep()) {
      await updateBrands();
      handleReset();
    }
  };

  const updateBrands = async () => {
    try {
      if (selectedBrands && selectedBrands.length > 0 && currentRace && brands?.length) {
        setIsLoading(true);

        const payload = brands.map((brand) => {
          const isBrandSelected = selectedBrands.some((selected) => selected.option === brand.guid);
          return {
            brand_guid: brand.guid,
            is_enabled: isBrandSelected,
          };
        });

        await NeurunApi.updateRaceBrands(currentRace?.guid || '', payload);
        toast.success('Race brands were successfully updated.', { hideProgressBar: true})
      }
    } catch (err) {
      console.error('Failed to update race brands:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    if (completed[step] || step === activeStep) {
      setActiveStep(step);
    }
  };
  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    setSelectedEvent(null);
    setSelectedRace(null);
    setCurrentRace(null);
    setEvents(null);
    setRaces(null);
  };

  const handleEventChange = (event: React.ChangeEvent<{}>, value: { label: string; option: string } | null) => {
    setSelectedEvent(value);
  };

  const handleRaceChange = (event: React.ChangeEvent<{}>, value: { label: string; option: string } | null) => {
    setSelectedRace(value);
  };

  const handleBrandChange = (event: React.SyntheticEvent, value: { label: string; option: string }[]) => {
    setSelectedBrands(value);
  };


  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <SelectEventComponent onChange={handleEventChange} value={selectedEvent} setEvents={setEvents} />
      case 1:
        return <SelectRaceComponent onChange={handleRaceChange} value={selectedRace} races={races || []} />
      case 2:
        return <SelectBrandsComponent eventGuid={selectedRace?.option || ''} onChange={handleBrandChange} setBrands={setBrands} />
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton
              color="inherit"
              onClick={handleStep(index)}
              disabled={!completed[index] && index !== activeStep}
              sx={{
                '&.Mui-disabled': {
                  background: 'transparent !important'
                },
              }}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <Box minHeight='350px' display='flex' flexDirection='column' justifyContent='space-around'>
          <Box>
            {renderStepContent()}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, marginTop: 'auto' }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleNext} sx={{ mr: 1 }} disabled={isNextDisabled || isLoading}>
              {isLastStep() ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </Box>
      </div>
      <ToastContainer />
    </Box>
  )
}

export default BrandConfiguration;
