export const isValidURL = (string: string) => {
  const urlPattern = new RegExp(
    '^https?:\\/\\/' + // Protocol (http or https)
    '((([a-zA-Z0-9-]+)\\.)+[a-zA-Z]{2,})' + // Domain name
    '(\\:[0-9]{1,5})?' + // Port (optional)
    '(\\/.*)?$', // Path (optional)
    'i' // Case-insensitive
  );
  return urlPattern.test(string);
};
