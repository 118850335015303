import { Box, CircularProgress, Grid, Switch, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomTextField from "../../components/CustomTextField";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "../../assets/img/arrow-back-icon.svg";
import NeurunApi from "../../api/neurun";
import { Event } from "../../types/models";

const CreateNewEvent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState<Event | null>(null);
  const { event_id } = useParams();
  const isEditing = !!event_id;
  const navigate = useNavigate();

  useEffect(() => {
    if (isEditing) {
      getEvent(event_id)
    }
  }, [isEditing]);

  const getEvent = async (eventId: string) => {
    setIsLoading(true);
    const result: Event = await NeurunApi.getEventRaceList(eventId);

    setEvent(result);
    formik.setFieldValue('name', result?.name);
    formik.setFieldValue('isEnabled', result?.is_enabled);
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      isEnabled: false,
    },
    validationSchema: yup.object({
      name: yup.string().required('Event name is required.')
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        if (isEditing && event?.guid) {
          const result = await NeurunApi.updateEvent(event?.guid, { name: values?.name, is_enabled: values.isEnabled });

          if (result) {
            navigate('/my-events');
          }
          setIsLoading(false);
          return;
        }
        const result = await NeurunApi.createEvent(values.name);
        if (result) {
          navigate('/my-events');
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error('Create new event error - ', e)
      }
    }
  })

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
        <div style={{width: '150px', display: 'flex', alignItems: 'center', cursor: 'pointer'}}
             onClick={() => navigate('/my-events')}>
          <img src={ArrowBackIcon} alt='Back icon' style={{width: '26px', height: '26px'}}/>
          <span>Back</span>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
        {isEditing && isLoading ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            mt={4}
          >
            <CircularProgress color='info' />
          </Box>
        ) : (
          <Box my={2}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item md={6} xs={12}>
                <CustomTextField
                  title='Event name'
                  name='name'
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  placeholder='Enter Event Name'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography fontWeight='600' fontSize='16px' fontFamily='Montserrat' py={0.5}>
                  Event enabled
                </Typography>
                <Switch
                  checked={formik.values.isEnabled}
                  name='isEnabled'
                  onChange={formik.handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <button type="submit" disabled={isLoading || !formik.values.name.trim()} style={styles.submitBtn}>Submit</button>
      </form>
    </Box>
  )
}

const styles = {
  submitBtn: {
    marginTop: '16px',
    padding: '14px',
    width: '100%',
    fontWeight: '500',
    fontSize: '17px',
    borderRadius: '12px',
    border: 'none',
    background: 'linear-gradient(to right, #4A55BA, #6271FF)',
    color: '#FFF',
    cursor: 'pointer',
  },
}

export default CreateNewEvent;
