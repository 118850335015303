import React, { useState } from "react";
import {
    Box,
    Typography,
    Paper,
    Fab,
    Modal,
    Tabs,
    Tab,
    Link,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import OpenView from '@mui/icons-material/OpenInNew';
import IconButton from "@mui/material/IconButton";

interface RaceOrganizerInfo {
    raceUrl: string
}

const RaceOrganizerInfo: React.FC<RaceOrganizerInfo> = (props) => {
    const { raceUrl } = props;
    const [open, setOpen] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => setTabIndex(newValue);

    const sections = [
        {
            label: "WEB",
            content: (<p>
                This section displays essential information scraped from the following URLs:<br/>
                <b>Website URL:</b> The main website for the race.<br/>
                <b>Registration URL:</b> The registration page for participants.<br/>
                <b>Resources URL:</b> Any additional resources or guides for the event.<br/>
                A scraping bot extracts key text information from these sources, which is then displayed here. Ensure your links are up-to-date to keep the data accurate.
            </p>),
        },
        {
            label: "GPX",
            content: (<p>
                If you've uploaded a GPX file, this section will display:<br/>
                <b>Route Details:</b> Including elevation data, distances, and checkpoints.<br/>
                <b>Waypoints:</b> Specific points along the route such as start, finish, and water stations.<br/>
                <b>AI Calculations:</b> Metrics like total elevation gain or estimated completion times.<br/>
                This information is essential for ensuring the race is well-planned and optimized for participants.
            </p>),
        },
        {
            label: "File",
            content: (<p>
                    If In this section, you can upload a PDF file containing additional information about the race, such as:<br/>
                    <b>Event schedules</b><br/>
                    <b>Route maps</b><br/>
                    <b>Instructions for participants</b><br/>
                Once uploaded, the data will be processed and displayed here for easy reference.
            </p>),
        },
        {
            label: "POI",
            content: (<p>
                Based on your GPX data, this section identifies:<br/>
                <b>Restaurants</b><br/>
                <b>Coffee shops</b><br/>
                <b>Restrooms</b><br/>
                <b>Any other useful points of interest (POIs) near the course</b><br/>
                This helps participants prepare better and enhances their experience during the event.
            </p>)
        },
        {
            label: "Updates",
            content: (<p>
                To update your data sources:<br/>
                <ol>
                    <li>Visit the respective links for the website, registration, or resources.</li>
                    <li>Modify the content in the provided forms.</li>
                    <li>Trigger the synchronization process to refresh the data.</li>
                </ol>
                <b>⚠️ Important: Any changes made in the system's current context will be overwritten during synchronization. Double-check your updates before syncing to avoid data loss.</b>
            </p>),
        },
    ];

    return (
        <Box sx={{ width: "100%", p: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Typography variant="h6">
                    Update Race Resources
                </Typography>
                <Link href={raceUrl} target="_blank" underline="hover">
                    <IconButton color="primary">
                        <OpenView />
                    </IconButton>
                </Link>
            </Box>
            <Fab
                color="primary"
                aria-label="info"
                onClick={handleOpen}
                sx={{ position: "fixed", top: '15%', right: 16 }}
            >
                <InfoIcon />
            </Fab>
            <Modal open={open} onClose={handleClose}>
                <Paper
                    elevation={3}
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80%",
                        maxWidth: 600,
                        maxHeight: "80%",
                        overflowY: "auto",
                        p: 3,
                        borderRadius: 2,
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                        <Typography variant="h6">
                            ⚠️ Important Information
                        </Typography>
                        <CloseIcon
                            onClick={handleClose}
                            sx={{
                                cursor: "pointer",
                                color: "#1976d2",
                            }}
                        />
                    </Box>
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="Race Organizer Tabs"
                        sx={{ mb: 2 }}
                    >
                        {sections.map((section, index) => (
                            <Tab key={index} label={section.label} />
                        ))}
                    </Tabs>
                    <Box sx={{ p: 2 }}>
                        <Typography
                            variant="body1"
                            component="div"
                            sx={{ whiteSpace: "pre-wrap" }}
                        >
                            {sections[tabIndex].content}
                        </Typography>
                    </Box>
                </Paper>
            </Modal>
        </Box>
    );
};

export default RaceOrganizerInfo;
