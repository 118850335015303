import React, { useEffect, useState } from "react";
import { Event } from "../../../types/models";
import { Autocomplete, Box, CircularProgress, TextField, Typography } from "@mui/material";
import NeurunApi from "../../../api/neurun";

interface SelectEventComponentProps {
  onChange: (
    event: React.ChangeEvent<{}>,
    value: { label: string; option: string } | null
  ) => void;
  value: { label: string; option: string } | null;
  setEvents: React.Dispatch<React.SetStateAction<Event[] | null>>
}

const SelectEventComponent: React.FC<SelectEventComponentProps> = ({ onChange, value, setEvents }) => {
  const [eventsOptions, setEventsOptions] = useState<{ label: string, option: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getEvents()
  }, []);

  const getEvents = async () => {
    setIsLoading(true);
    const result: Event[] = await NeurunApi.getProviderEvents();

    const options = result?.map(i => ({ label: i.name, option: i.guid }));
    setEvents(result);
    setEventsOptions(options)
    setIsLoading(false);
  }

  return (
    <Box p={2}>
      {isLoading ? (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          mt={4}
        >
          <CircularProgress color='info' />
        </Box>
      ) : (
        <Box>
          <Typography fontWeight='600' fontSize='16px' fontFamily='Montserrat' py={0.5}>
            Event
          </Typography>
          <Autocomplete
            value={value}
            disablePortal
            options={eventsOptions}
            sx={{ width: 300 }}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={styles.inputField}
                variant="outlined"
                fullWidth
                placeholder='Select event'
              />
            )}
          />
        </Box>
      )}
    </Box>
  )
}

const styles = {
  inputField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& fieldset': {
        borderColor: '#ccc',
      },
    },
    '& .MuiInputBase-input': {
      padding: '10px 14px',
    },
  },
  requiredField: {
    color: 'red',
    marginLeft: '8px'
  }
}

export default SelectEventComponent
