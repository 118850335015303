import neurunAxios, {neurunAxiosInstance} from "./axiosInstance";
import {
    EventBrands,
    GpxFile, OperationResult,
    RaceBrand,
    RaceContextParsed,
    UpdateEventPayload,
    UpdateGpxPayload,
    UpdateRaceBrandsPayload
} from "../../types/models";

class NeurunApi {
    static async getNeurunTokens(api_key: string): Promise<{ access_token: string, refresh_token: string }>{
        const result = await neurunAxios.post(`/login`, {
            api_key: api_key || this.getApiKey()
        })

        return result.data
    }

    static async refreshNeurunTokens(refreshToken: string): Promise<{ access_token: string, refresh_token: string }> {
        const result = await neurunAxios.post(`/refresh`, {
            refresh_token: refreshToken
        })

        return result.data
    }

    static async getNjukoToken(editionId: string): Promise<{ token: string }> {
        const result = await neurunAxios.post(`/njuko/token`, {
            edition_id: editionId
        })

        return result.data
    }

    static async getRace(editionId: string) {
        const result = await neurunAxios.get(`race/${editionId}`)

        return result.data
    }

    static async uploadGpx(payload: FormData): Promise<GpxFile> {
        const result = await neurunAxios.post('gpx/upload', payload, {
            headers: {
                "Content-Type": 'multipart/form-data',
            }
        })

        return result.data
    }

    static async createRace(payload: Record<string, any>) {
        const result = await neurunAxios.post('race', payload, {
            headers: {
                "Content-Type": 'multipart/form-data',
            }
        })

        return result.data
    }

    static async updateRace(payload: Record<string, any>, guid: string) {
        const result = await neurunAxios.put(`race/${guid}`, payload, {
            headers: {
                "Content-Type": 'multipart/form-data',
            }
        })

        return result.data
    }

    static async updateGpx(payload: UpdateGpxPayload, gpx_guid: string) {
        const result = await neurunAxios.put(`gpx/${gpx_guid}`, payload);

        return result?.data
    }

    static async getGpxData(race_guid: string): Promise<GpxFile> {
        const result = await neurunAxiosInstance.get(`/race/gpx/${race_guid}`);

        return result?.data
    }

    static getApiKey() {
        const env = process.env.REACT_APP_ENV || 'PROD'
        return process.env[`REACT_APP_${env}_NEURUN_API_KEY`];
    }

    static async downloadGpxFile(gpx_file_guid: string) {
        const result = await neurunAxios.get(`/gpx/download/${gpx_file_guid}`, {
            responseType: 'blob'
        });
        return result?.data;
    }

    static async getProviderRaceList(skip = 0, take = 5) {
        const result = await neurunAxios.get(`/race/list?take=${take}&skip=${skip}`);

        return result?.data;
    }

    static async getEventRaceList(event_guid: string) {
        const result = await neurunAxios.get(`/event/${event_guid}`);

        return result?.data;
    }

    static async getProviderEvents() {
        const result = await neurunAxios.get('/events');

        return result?.data;
    }

    static async attachRaceToEvent(race_guid: string, race_event_guid: string) {
        const result = await neurunAxios.post(`/race/${race_guid}/event/attach/${race_event_guid}`, undefined)

        return result?.data;
    }

    static async createEvent(name: string) {
        const result = await neurunAxios.post('/event', { name });

        return result?.data;
    }

    static async updateEvent(eventId: string, data: UpdateEventPayload) {
        const result = await neurunAxios.put(`/event/${eventId}`, data);

        return result?.data;
    }

    static async deleteEvent(eventId: string) {
        const result = await neurunAxios.delete(`/event/${eventId}`);

        return result?.data;
    }

    static async getRaceBrands(eventId: string): Promise<RaceBrand[]> {
        const result = await neurunAxios.get(`/products/brands/${eventId}`);

        return result?.data;
    }

    static async updateRaceBrands(eventId: string, data: UpdateRaceBrandsPayload[]) {
        const result = await neurunAxios.post(`/products/brands/bulk/${eventId}`, data);

        return result?.data;
    }

    static async getEventsBrands(): Promise<EventBrands[]> {
        const result = await neurunAxios.get('/events/brands');

        return result?.data;
    }

    static async getParsedRaceContext(raceGuid: string): Promise<RaceContextParsed> {
        const result = await neurunAxios.get(`/race/context/parsed/${raceGuid}`)

        return result?.data;
    }

    static async saveRaceContext(raceGuid: string, payload: RaceContextParsed): Promise<OperationResult> {
        const result = await neurunAxios.post(`/race/context/parsed/${raceGuid}`, payload)

        return result?.data;
    }
    static async syncRaceContext(raceGuid: string): Promise<OperationResult> {
        const result = await neurunAxios.post(`/race/context/update/${raceGuid}`)

        return result?.data;
    }
}

export default NeurunApi
