import React, { useEffect, useState } from "react";
import NeurunApi from "../../../api/neurun";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {EventBrands, Race} from "../../../types/models";

const ConnectedBrandsList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [eventBrands, setEventBrands] = useState<EventBrands[]>([]);

  useEffect(() => {
    getEventBrands();
  }, []);

  const getEventBrands = async () => {
    setIsLoading(true);
    try {
      const result: EventBrands[] = await NeurunApi.getEventsBrands();
      setEventBrands(result);
    } catch (error) {
      console.error("Failed to fetch event brands:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%", marginTop: "16px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
        <Typography variant="h4" fontWeight="500">
          Brands List
        </Typography>
      </div>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
          <CircularProgress color="info" />
        </Box>
      ) : (
        <div>
          {!eventBrands?.length ? (
            <Typography textAlign="center">There are no attached brands</Typography>
          ) : (
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Event</TableCell>
                      <TableCell align="left">Race</TableCell>
                      <TableCell align="left">Brands</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eventBrands.map((event: EventBrands) =>
                      event.races.map((race: Race) => (
                        <TableRow key={race.guid} hover>
                          <TableCell align="left">{event.name}</TableCell>
                          <TableCell align="left">{race.name}</TableCell>
                          <TableCell align="left">
                            {race.brands.length > 0
                              ? race.brands.map((brand) => brand.name).join(", ")
                              : "No brands attached"}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </div>
      )}
    </Box>
  );
};

export default ConnectedBrandsList;
