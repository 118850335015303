import React, { useEffect, useState } from "react";
import { RaceBrand } from "../../../types/models";
import NeurunApi from "../../../api/neurun";
import { Autocomplete, Box, CircularProgress, TextField, Typography } from "@mui/material";

interface SelectBrandsComponentProps {
  eventGuid: string;
  onChange?: (
    event: React.SyntheticEvent,
    value: { label: string; option: string }[]
  ) => void;
  setBrands: React.Dispatch<React.SetStateAction<RaceBrand[] | null>>;
}

const SelectBrandsComponent: React.FC<SelectBrandsComponentProps> = ({
                                                                       eventGuid,
                                                                       onChange,
                                                                       setBrands,
                                                                     }) => {
  const [eventsOptions, setEventsOptions] = useState<{ label: string; option: string }[]>([]);
  const [selectedValues, setSelectedValues] = useState<{ label: string; option: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getBrands();
  }, []);

  const getBrands = async () => {
    setIsLoading(true);
    const result = await NeurunApi.getRaceBrands(eventGuid);

    const options = result?.map((i) => ({
      label: i.name,
      option: i.guid,
    }));

    const preSelectedValues = result
      ?.filter((brand) => brand.is_enabled)
      .map((brand) => ({
        label: brand.name,
        option: brand.guid,
      }));

    setBrands(result);
    setEventsOptions(options);
    setSelectedValues(preSelectedValues || []);
    setIsLoading(false);
  };

  const handleSelectionChange = (
    event: React.SyntheticEvent,
    value: { label: string; option: string }[]
  ) => {
    setSelectedValues(value);
    if (onChange) {
      onChange(event, value);
    }
  };

  return (
    <Box p={2}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
          <CircularProgress color="info" />
        </Box>
      ) : (
        <Box>
          <Typography fontWeight="600" fontSize="16px" fontFamily="Montserrat" py={0.5}>
            Brands
          </Typography>
          <Autocomplete
            multiple
            disablePortal
            options={eventsOptions}
            value={selectedValues}
            onChange={handleSelectionChange}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.option === value.option}
            sx={{ width: 400 }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={styles.inputField}
                variant="outlined"
                fullWidth
                placeholder="Select brands"
              />
            )}
          />
        </Box>
      )}
    </Box>
  );
};

const styles = {
  inputField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& fieldset': {
        borderColor: '#ccc',
      },
    },
    '& .MuiInputBase-input': {
      padding: '10px 14px',
    },
  },
  requiredField: {
    color: 'red',
    marginLeft: '8px',
  },
};

export default SelectBrandsComponent;
