import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Event, OrderedRace } from "../../../types/models";
import { convertToTitleCase } from "../../../helpers/convertToTitleCase";
import { FormikProps } from "formik";
import { HexColorPicker, HexColorInput } from "react-colorful";

interface MyFormValues {
  main_color: string;
}

interface WidgetStylingConfiguration {
  main_color: string
}

interface CustomizeWidgetComponentProps {
  eventId: string;
  event: Event | null;
  races: OrderedRace[] | [];
  setRaces: React.Dispatch<React.SetStateAction<OrderedRace[] | []>>;
  formik: FormikProps<MyFormValues>;
}

interface DraggableItem {
  id: string;
  index: number;
}

const CustomizeWidgetComponent: React.FC<CustomizeWidgetComponentProps> = ({ eventId, event, races, setRaces, formik }) => {
  const [widgetStylingConfiguration, setWidgetStylingConfiguration] = useState<WidgetStylingConfiguration | null>(null);

  useEffect(() => {
    if (event && event?.races) {
      if (event?.widget_configuration) {
        formik.setFieldValue('main_color', event?.widget_configuration?.style?.main_color);
      }

      const mappedRaces = event?.races.map((i) => {
        const orderConfig = event?.race_ordering_config?.find(j => j.guid === i.guid);

        return {
          id: i?.guid || '',
          name: convertToTitleCase(i?.race_type || ''),
          display_order: orderConfig?.order || 0
        }
      });

      const sortedRaces = mappedRaces.sort((a, b) => a.display_order - b.display_order);

      setRaces(sortedRaces);
    }
  }, [event]);

  useEffect(() => {
    const { main_color } = formik.values;

    setWidgetStylingConfiguration({
      main_color,
    });
  }, [formik.values]);

  const moveRace = (dragIndex: number, hoverIndex: number) => {
    const updatedRaces = [...races];
    const [draggedRace] = updatedRaces.splice(dragIndex, 1);
    updatedRaces.splice(hoverIndex, 0, draggedRace);

    setRaces(
      updatedRaces.map((race, index) => ({
        ...race,
        display_order: index + 1,
      }))
    );
  };

  return (
    <Box>
      {!races?.length ? (
        <Box mt={3} display='flex' justifyContent='center' alignItems='center'>
          <Typography>That event haven't any race.</Typography>
        </Box>
      ) : (
        <>
          <Box my={3}>
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} mt={4}>
              <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                <Typography fontWeight='600' fontSize='16px' fontFamily='Montserrat' py={2}>
                  Selected color
                </Typography>
                <HexColorInput color={formik.values.main_color} onChange={(value) => formik.setFieldValue('main_color', value)} style={styles.colorInput} />
                <HexColorPicker color={formik.values.main_color} onChange={(value) => formik.setFieldValue('main_color', value)} />
              </Grid>
              <Grid item md={6} xs={12}>
                <div style={{ maxWidth: '620px' }}>
                  <Typography fontWeight='600' fontSize='16px' fontFamily='Montserrat' py={2} textAlign='right'>
                    Drag and drop races to change their order.
                  </Typography>
                  <Box>
                    {eventId ? (
                      <>
                        <DndProvider backend={HTML5Backend}>
                          <div style={{ maxWidth: "615px", margin: "0 auto", display: 'flex', gap: '8px', justifyContent: 'flex-end', marginBottom: '-22px' }}>
                            {races.map((race, index) => (
                              <RaceCard
                                key={race.id}
                                race={race}
                                index={index}
                                moveRace={moveRace}
                                mainColor={formik.values.main_color}
                              />
                            ))}
                          </div>
                        </DndProvider>
                        {widgetStylingConfiguration && (
                          <search-bar event='test' demo='true' configuration={JSON.stringify({ style: widgetStylingConfiguration })}></search-bar>
                        )}
                      </>
                    ) : (
                      <Typography>Something went wrong. Please select another event</Typography>
                    )}
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  )
}

const RaceCard: React.FC<{
  race: {id: string, name: string, display_order: number};
  index: number;
  moveRace: (dragIndex: number, hoverIndex: number) => void;
  mainColor: string;
}> = ({ race, index, moveRace, mainColor = '#4C57BC' }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [, drag] = useDrag({
    type: "RACE",
    item: { id: race.id, index },
  });

  const [, drop] = useDrop<DraggableItem>({
    accept: "RACE",
    hover: (item, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveRace(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{
        color: mainColor,
        fontWeight: index === 0 ? '700' : '400',
        opacity: index === 0 ? '1' : '0.5',
        fontFamily: 'Figtree',
        cursor: "move"
      }}
    >
      {race.name}
    </div>
  );
};

const styles = {
  colorInput: {
    height: '43px',
    maxWidth: '250px',
    marginBottom: '16px',
    padding: '10px 14px',
    borderRadius: '8px'
  }
}

export default CustomizeWidgetComponent;
