import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import {TabList} from "@mui/lab";
import BrandConfiguration from "./BrandConfiguration";
import ConnectedBrandsList from "./ConnectedBrandsList";

interface StyledTabProps {
  label: string;
  value: string
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  marginRight: theme.spacing(1),
  color: 'linear-gradient(to right, #4954B8, #606FF8)',
  '&.Mui-selected': {
    color: 'linear-gradient(to right, #4954B8, #606FF8)',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

const SponsorManagementPage = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={value}>
        <Box sx={{ marginBottom: '16px' }}>
          <TabList onChange={handleChange} centered variant='fullWidth'>
            <StyledTab label="Brand configuration" value="1" />
            <StyledTab label="List of connected brands" value="2" />
          </TabList>
        </Box>
        <Box>
          <TabPanel value="1">
            <BrandConfiguration />
          </TabPanel>
          <TabPanel value="2">
            <ConnectedBrandsList />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
}

export default SponsorManagementPage
