import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";

interface CodeGeneratorComponentProps {
  eventId: string
}

interface CodeSnippetProps {
  code: string
}

const CodeGeneratorComponent: React.FC<CodeGeneratorComponentProps> = ({ eventId }) => {
  const searchBarCodeSnippet = `
      <search-bar event='${eventId}'></search-bar>
  `;

  const getScriptCodeSnippet = () => {
    const env = process.env.REACT_APP_ENV || 'PROD'
    const baseUrl =  process.env[`REACT_APP_${env}_NEURUN_URL`];

    return `
      <script type="module" src='${baseUrl}/polyfills.js'></script>
<script type="module" src='${baseUrl}/main.js'></script>
    `
  }

  return (
    <Box p={2}>
      <Box>
        <Box>
          <Typography><strong>1. </strong>Paste that code in your code where you want to show search bar</Typography>
          <CodeComponent code={searchBarCodeSnippet} />
        </Box>
        <Box my={2}>
          <Typography><strong>2. </strong>Paste that code inside of your <strong>HTML</strong> file</Typography>
          <CodeComponent code={getScriptCodeSnippet()}/>
        </Box>
      </Box>
    </Box>
  )
}

const CodeComponent: React.FC<CodeSnippetProps> = ({code}) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  return (
    <div style={{position: "relative"}}>
      <SyntaxHighlighter
        language="html"
        style={atomDark}
        customStyle={{
          borderRadius: "5px",
          padding: "1rem",
          border: "1px solid #ddd",
          fontSize: "0.9rem",
        }}
      >
        {code.trim()}
      </SyntaxHighlighter>
      <button
        onClick={() => handleCopy(code.trim())}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          padding: "5px 10px",
          backgroundColor: "rgb(33, 150, 243)",
          color: "#fff",
          border: "none",
          borderRadius: "3px",
          cursor: "pointer",
        }}
      >
        {copySuccess ? "Copied!" : "Copy"}
      </button>
    </div>
  )
}

export default CodeGeneratorComponent;
