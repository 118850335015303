import {
  Box,
  CircularProgress,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NeurunApi from "../../api/neurun";
import { Event } from "../../types/models";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers/formatDate";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModalComponent from "../../components/ModalComponent";
import CustomButton from "../../components/CustomButton";

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: true,
    label: 'Created at',
  },
  {
    id: 'is_active',
    numeric: false,
    disablePadding: true,
    label: 'Is active',
  },
]

const EventsListPage = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRowId(null);
  };

  useEffect(() => {
    getEvents()
  }, []);

  const getEvents = async () => {
    setIsLoading(true);
    const result = await NeurunApi.getProviderEvents();

    setEvents(result)
    setIsLoading(false);
  }

  const deleteEvent = async (eventId: string) => {
    try {
      setIsLoading(true);
      await NeurunApi.deleteEvent(eventId);
      await getEvents();
    } catch (e) {
      setIsLoading(false);
    }
  }

  const onAddNewEventClick = () => {
    navigate('/event/create')
  }

  const handleRowClick = (id: string) => {
    navigate(`/my-races/${id}`)
  }

  const handleEditClick = (id: string) => {
    navigate(`/event/edit/${id}`);
  };

  const handleDeleteEvent = (id: string) => {
    setSelectedRowId(id);
    handleOpenModal();
  }

  const handleConfirm = async () => {
    if (selectedRowId) {
      await deleteEvent(selectedRowId);
      handleCloseModal();
    }
  }

  return (
    <div>
      <Box sx={{width: '100%', marginTop: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
          <Typography variant='h4' fontWeight='500'>Events list</Typography>
          <CustomButton title='Add new event' variant='outlined' style={{ width: '150px', fontSize: '15px', padding: '10px' }} onClick={onAddNewEventClick} />
        </div>
        {isLoading ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            mt={4}
          >
            <CircularProgress color='info' />
          </Box>
        ) : (
          <div>
            {!events?.length && !isLoading ? (
              <Typography textAlign='center'>There are no events</Typography>
            ) : (
              <Paper sx={{width: '100%', mb: 2}}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size='medium'
                  >
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align='left'
                            padding='normal'
                          >
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {events.map((row) => {
                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleRowClick(row?.guid)}
                            tabIndex={-1}
                            key={row.guid}
                            sx={{ cursor: 'pointer' }}
                          >
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">{formatDate(row.created_at)}</TableCell>
                            <TableCell align="left">{row.is_active ? 'Active' : 'Inactive'}</TableCell>
                            <TableCell align="left" width='30px'>
                              <Tooltip title='Edit event' placement="top">
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditClick(row.guid)
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="left" width='30px'>
                              <Tooltip title='Delete event' placement="top">
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteEvent(row.guid)
                                  }}
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
          </div>
        )}
        <ModalComponent
          open={isModalOpen}
          title="Delete event"
          description="Are you sure you want to delete event?"
          onConfirm={handleConfirm}
          onCancel={handleCloseModal}
        />
      </Box>
    </div>
  )
}

export default EventsListPage
