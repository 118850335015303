import React, { ReactNode } from "react";
import { SelectorOptions } from "../../types/models";
import {
  Box,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";

interface CustomAutocompleteProps {
  title: string;
  name: string;
  onChange: (event: SelectChangeEvent<string>, child: ReactNode) => void
  value: string;
  placeholder: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: string;
  options: SelectorOptions[];
  required?: boolean
}

const CustomSelectField: React.FC<CustomAutocompleteProps> =  ({ title, name, onChange, value, placeholder, onBlur, error, options, required = false }) => {
  return (
    <Box width='100%'>
      <Typography fontWeight='600' fontSize='16px' fontFamily='Montserrat' py={0.5}>
        {title}
        {required ? (
          <span style={styles.requiredField}>*</span>
        ) : null}
      </Typography>
      <Select
        value={value}
        fullWidth
        displayEmpty
        onBlur={onBlur}
        name={name}
        sx={styles.inputField}
        onChange={onChange}
        error={!!error}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (!selected) {
            return <span style={{ opacity: '0.5' }}>{placeholder}</span>;
          }

          const value = options.find(i => i.value === selected)

          return value?.option;
        }}
      >
        {options.map(({ option, value }) => {
          return (
            <MenuItem
              key={option}
              value={value}
            >
              {option}
            </MenuItem>
          )
        })}
      </Select>
      <FormHelperText style={{ color: '#d32f2f', marginLeft: '14px' }}>{error}</FormHelperText>
    </Box>
  )
}

const styles = {
  inputField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& fieldset': {
        borderColor: '#ccc',
      },
    },
    '& .MuiInputBase-input': {
      padding: '10px 14px',
    },
  },
  requiredField: {
    color: 'red',
    marginLeft: '8px'
  }
}

export default CustomSelectField;
