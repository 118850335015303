import React, {useEffect, useState} from "react";
import NeurunApi from "../../api/neurun";
import {isExpired} from 'react-jwt'
import { useLocation } from 'react-router-dom';

interface AuthProviderProps {
    children: React.ReactNode;
}

const AuthProvider = (props: AuthProviderProps) => {
    const [isAuth, setIsAuth] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes('njuko')) {
            return
        };
        processToken()
    }, [])

    const processToken = async () => {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh_token');
        const secretKey = localStorage.getItem('secret_key') || '';
        if (!token) {
            const { access_token, refresh_token } = await NeurunApi.getNeurunTokens(secretKey)

            localStorage.setItem('token', access_token)
            localStorage.setItem('refresh_token', refresh_token)
            setIsAuth(true)
            return
        } else if (refreshToken && isExpired(token)) {
            const { access_token, refresh_token } = await NeurunApi.refreshNeurunTokens(refreshToken)
            localStorage.setItem('token', access_token)
            localStorage.setItem('refresh_token', refresh_token)
            setIsAuth(true)
            return
        }
    }

    if (!isAuth) {
        // navigate('/login');
        // return (
        //     <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        //         <CircularProgress />
        //     </Box>
        // )
    }

    return <>{props.children}</>
}

export default AuthProvider
