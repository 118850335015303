import Dialog from '@mui/material/Dialog';
import {Box, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import CustomButton from "../CustomButton";

interface ModalComponentProps {
  open: boolean;
  title?: string;
  description?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
 open,
 title = "Are you sure?",
 description = "Do you want to proceed?",
 onConfirm,
 onCancel,
}) => {

  return (
    <Dialog open={open} onClose={onCancel}>
      <Box px={1}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography>{description}</Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton type='submit' title='Delete' onClick={onConfirm} />
          <CustomButton variant='secondary' title='Cancel' onClick={onCancel} />
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default ModalComponent;
