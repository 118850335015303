import React, { useEffect, useState } from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { Race } from "../../types/models";

interface SelectRaceComponentProps {
  onChange: (
    event: React.ChangeEvent<{}>,
    value: { label: string; option: string } | null
  ) => void;
  value: { label: string; option: string } | null;
  races: Race[];
}

const SelectRaceComponent: React.FC<SelectRaceComponentProps> = ({ onChange, value, races }) => {
  const [raceOptions, setRaceOptions] = useState<{ label: string, option: string }[]>([]);

  useEffect(() => {
    const options = races?.map(i => ({ label: i.name, option: i.guid || '' }));
    setRaceOptions(options)
  }, [races]);

  return (
    <Box p={2}>
      <Box>
        <Typography fontWeight='600' fontSize='16px' fontFamily='Montserrat' py={0.5}>
          Race
        </Typography>
        <Autocomplete
          value={value}
          disablePortal
          options={raceOptions}
          sx={{ width: 300 }}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={styles.inputField}
              variant="outlined"
              fullWidth
              placeholder='Select event'
            />
          )}
        />
      </Box>
    </Box>
  )
}

const styles = {
  inputField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& fieldset': {
        borderColor: '#ccc',
      },
    },
    '& .MuiInputBase-input': {
      padding: '10px 14px',
    },
  },
  requiredField: {
    color: 'red',
    marginLeft: '8px'
  }
}

export default SelectRaceComponent;
